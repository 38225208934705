import {graphql, useStaticQuery} from "gatsby";
import {useI18next} from "gatsby-plugin-react-i18next";
import React from "react";

interface FooterGraphResponse {
	id:                          string;
	languages_code:              LanguagesCode;
	legal_title:                 string;
	copyright_notice:            string;
	change_cookie_consent_title: string;
	about_title:                 string;
	about_description:           string;
	disclaimer_title:            string;
	disclaimer_page_id:          PageID;
	legal_notice_title:          string;
	legal_notice_page_id:        PageID;
	privacy_policy_title:        string;
	privacy_policy_page_id:      PageID;
	terms_conditions_title:      string;
	terms_conditions_page_id:    PageID;
}

interface PageID {
	page_slug: string;
}

interface LanguagesCode {
	code:   string;
	name:   string;
	locale: string;
}

export function Footer() {

	const data = useStaticQuery(graphql`
    {
      directus {
        component_footer {
          id
          translations {
            id
            languages_code {
              code
              name
              locale
            }
            legal_title
            copyright_notice
            change_cookie_consent_title
            about_title
            about_description
            disclaimer_title
            disclaimer_page_id {
              page_slug
            }
            legal_notice_title
            legal_notice_page_id {
              page_slug
            }
            privacy_policy_title
            privacy_policy_page_id {
              page_slug
            }
            terms_conditions_title
            terms_conditions_page_id {
              page_slug
            }
          }
        }
      }
    }
  `)

	// i18n
	const {language, languages, originalPath, t} = useI18next();

	const components: any[] = data.directus.component_footer.map((component: any[]) => component);
	const translations: any[] = components.map((translation: any[]) => translation);
	const translation_localized: FooterGraphResponse[] = translations.map((translation) => translation.translations.filter((tr: any) => tr.languages_code.locale === language)).flat();
	const viewModel = translation_localized[0];

	function changeConsent(e: any) {
		e.preventDefault();
		// @ts-ignore
		klaro.show()
	}

	return (
		<>

			<div className="bg-[#262626]">

				{/* Container */}
				<div className="layout-containerized font-sans">

					{/*<pre className="bg-white">{JSON.stringify(translation_localized, null, 4)}</pre>*/}

					{/* Grid */}
					<div className="m-0 py-5 grid grid-cols-12 gap-4 justify-evenly">
						<div className="col-span-12 lg:col-span-9 text-base-300 text-sm">
							{/* Title About Us */}
							<span className="uppercase font-bold text-white">{viewModel.about_title}</span>
							{/* Desc */}
							<p className="font-light text-gray-400">{viewModel.about_description}</p>
						</div>
						<div className="col-span-12 lg:col-span-3 text-white">
							<div className="flex flex-col text-sm">
								{/* Title Legal */}
								<span className="uppercase font-bold">{viewModel.legal_title}</span>
								{/* Links */}
								<a className="hover:text-salmonRed font-light text-gray-400" href={`/${viewModel.languages_code.locale}/${viewModel.disclaimer_page_id.page_slug}`}><span>{viewModel.disclaimer_title}</span></a>
								<a className="hover:text-salmonRed font-light text-gray-400" href={`/${viewModel.languages_code.locale}/${viewModel.privacy_policy_page_id.page_slug}`}><span>{viewModel.privacy_policy_title}</span></a>
								<a className="hover:text-salmonRed font-light text-gray-400" href={`/${viewModel.languages_code.locale}/${viewModel.terms_conditions_page_id.page_slug}`}><span>{viewModel.terms_conditions_title}</span></a>
								<a className="hover:text-salmonRed font-light text-gray-400" href={`/${viewModel.languages_code.locale}/${viewModel.legal_notice_page_id.page_slug}`}><span>{viewModel.legal_notice_title}</span></a>
								<a className="hover:text-salmonRed font-light text-gray-400" href={`#`} onClick={changeConsent}><span>{viewModel.change_cookie_consent_title}</span></a>
							</div>

						</div>
						<div className="text-white col-span-12">
							{/* Copyright */}
							<span className="font-light text-xs text-gray-400">
								{viewModel.copyright_notice.replace("{start}", "2014").replace("{now}", String(new Date().getFullYear()))}
							</span>
						</div>
					</div>
				</div>
			</div>

		</>
	)
}
