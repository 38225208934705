import React, {useEffect} from "react";
import {Footer} from "./footer/footer";
import Appbar from "./navbar/navbar";
import {Helmet} from "react-helmet";
// @ts-ignore
const Layout = ({children}) => {

	useEffect(() => {
		// @ts-ignore
		const script = document.createElement("script");
		script.src="/klaro.js"
		script.async=false;
		script.defer=true;
		// @ts-ignore
		document.body.appendChild(script);
	});

	return (
		<>
			<Helmet>
				<link rel="icon" href={"../images/icon.png"} />
			</Helmet>
			<div className="application">
			</div>
			<div className="bg-white text-black">
				<Appbar/>
				<div>
					<main>{children}</main>
				</div>
				<Footer/>
			</div>
		</>
	)
}
export default Layout
