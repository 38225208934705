import {Disclosure} from '@headlessui/react'
import {MenuIcon, XIcon} from '@heroicons/react/outline'
import {StaticImage} from "gatsby-plugin-image"
import {useI18next} from 'gatsby-plugin-react-i18next';
import React from 'react'
import {AppSection, useAppStore} from "../../state/app_state";

function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(' ')
}



export default function Appbar() {

	// State
	const appSection = useAppStore(state => state.appSection);
	const setIngredientSection = useAppStore(state => state.setIngredients);
	const setArticlesSection = useAppStore(state => state.setArticles);

	// i18n
	const {language, languages, originalPath, t} = useI18next();


	// button content
	const navigation = [
		{name: t('Navbar.ingredients'), href: `${t('Site.sushi_ingredients_uri')}`, current: appSection === AppSection.ingredients, onClickHandler: setIngredientSection},
		{name: t('Navbar.articles'), href: `${t('Site.article_feed_uri')}`, current: appSection === AppSection.articles, onClickHandler: setArticlesSection}
	]

	return (
		<Disclosure as="nav" className="bg-salmonRed">
			{({open}) => (
				<>
					{/* Container */}
					<div className="layout-containerized">

						{/* Flex Item Container */}
						<div className="relative flex items-center justify-between h-16">

							{/* Mobile menu button*/}
							<div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
								<Disclosure.Button
									className="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
									<span className="sr-only">Open main menu</span>
									{open ? (
										<XIcon className="block h-6 w-6" aria-hidden="true"/>
									) : (
										<MenuIcon className="block h-6 w-6" aria-hidden="true"/>
									)}
								</Disclosure.Button>
							</div>

							{/* Left Items */}
							<div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
								<div className="flex-shrink-0 flex items-center">
									{/* Image */}
									<a href={t("Site.home")}>
									<StaticImage
										className="h-auto"
										src="../../images/logo/sushipedia-logo-full.png"
										width={150}
										quality={75}
										formats={["auto", "webp", "avif"]}
										alt={t('Navbar.logo')}
										style={{}}
									/>
									</a>
								</div>
							</div>

							{/* Right Items */}
							<div
								className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
								<div className="hidden sm:block sm:ml-6">
									<div className="flex space-x-4 ">
										{navigation.map((item) => (
											<a
												key={item.name}
												href={item.href}
												className={classNames(
													item.current ? 'border-b-2 border-white' : '', 'font-bold text-base text-white hover:text-noriGreen'
												)}
												aria-current={item.current ? 'page' : undefined}
												onClick={item.onClickHandler}
											>
												{item.name}
											</a>
										))}
									</div>

								</div>
							</div>

						</div>
					</div>

					{/* Mobile Menu */}
					<Disclosure.Panel className="sm:hidden">
						<div className="px-2 pt-2 pb-3 space-y-1">
							{navigation.map((item) => (
								<Disclosure.Button
									as="a"
									key={item.name}
									href={item.href}
									className={classNames(
										item.current ? 'bg-gray-900 text-white' : 'font-sans text-white hover:bg-gray-700 hover:text-white', 'block px-3 py-2 rounded-md text-base font-medium'
									)}
									aria-current={item.current ? 'page' : undefined}
									onClick={item.onClickHandler}
								>
									{item.name}
								</Disclosure.Button>
							))}
						</div>
					</Disclosure.Panel>
				</>
			)}
		</Disclosure>
	)
}
