import create, {SetState} from "zustand";
import { devtools } from "zustand/middleware";

export enum AppSection {
	initial,
	landing,
	ingredients,
	articles,
	legal
}

export type AppStore = {
	appSection: AppSection
	setLanding: () => void,
	setIngredients: () => void,
	setArticles: () => void,
	setLegal: () => void
}

const initAppStore = (set: SetState<AppStore>) => ({
	appSection: AppSection.initial,
	setLanding: () => set({appSection: AppSection.landing}),
	setIngredients: () => set({appSection: AppSection.ingredients}),
	setArticles: () => set({appSection: AppSection.articles}),
	setLegal: () => set( {appSection: AppSection.legal})
})

// Create the state store and expose it for further usage
export const useAppStore = create<AppStore>(
	(set) => ({
		...initAppStore(set)
	})
)
